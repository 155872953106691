<script lang="ts" setup>
import { computed } from 'vue'

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true
})

const htmlAttrs = computed(() => head.value.htmlAttrs!)
useHead({
  meta: [
    {
      name: 'ahrefs-site-verification',
      content: 'f8f3f12ecdea01cac5531f59cf6aad1313d2527ad6357bad6d45b47211b81894'
    }
  ]
})

const route = useRoute()

useHead({ link: [{ rel: 'canonical', href: route.path }] })
</script>

<script lang="ts">
export default {
  name: 'EmptyLayout'
}
</script>

<template>
  <Html
    :lang="htmlAttrs.lang"
    :dir="htmlAttrs.dir">
    <main>
      <slot />
    </main>
  </Html>
</template>
